<template>
  <div v-if="items && items.length > 0">
    <CustomTable
      :items="items"
      :fields="fields"
      @handleSort="handleSort"
      @handleDelete="handleDelete"
    />
  </div>
  <div v-else>
    <no-data />
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import NoData from '@/views/components/nodata/index.vue'
import CustomTable from '@/views/components/custom-table/index.vue'

export default {
  components: {
    NoData,
    CustomTable,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'fullname', label: 'Full name', sortable: true },
        { key: 'content', label: 'Content', sortable: true },
        // { key: 'created_at', label: 'Created at', sortable: true },
        { key: 'more', label: 'More', sortable: false },
        // { key: 'sort', label: 'Sort', sortable: false },
      ],
      items: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    // async handleSort(data) {
    //   const { item } = data
    //   const { method } = data
    //   try {
    //     const params = {
    //       template_id: JSON.parse(localStorage.getItem('templateID')).id,
    //       type: 1,
    //       ids: [],
    //     }
    //     if (method === 'up') {
    //       params.ids = [item.item.id, this.items[item.index - 1].id]
    //     }
    //     if (method === 'down') {
    //       params.ids = [item.item.id, this.items[item.index + 1].id]
    //     }
    //     const res = await Request.post(
    //       this.$http,
    //       `${process.env.VUE_APP_API_URL}/platform_customer_fake/change_position`,
    //       params,
    //     )
    //     if (res.status === 200) {
    //       if (res.data.status) {
    //         this.loadList()
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Login fail',
    //             icon: 'AlertOctagonIcon',
    //             variant: 'danger',
    //             text: String(this.showError(res.data.error, ',')),
    //           },
    //         })
    //       }
    //     }
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Opps! Something wrong',
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger',
    //         text: String(error),
    //       },
    //     })
    //   }
    // },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_customer_fakes?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const templateId = JSON.parse(localStorage.getItem('templateID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_customer_fake/${id}?template_id=${templateId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Customer Fake has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
